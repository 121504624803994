import { Controller } from 'stimulus'

export default class extends Controller {

  static targets = ['form', 'hasStartAt', 'startAt', 'hasEndAt', 'endAt']

  initialize() {
    this.updateInputs()
  }

  updateInputs() {
    if (this.hasStartAtTarget.checked) {
      this.startAtTarget.classList.remove('hidden')
    } else {
      this.startAtTarget.classList.add('hidden')
    }

    if (this.hasEndAtTarget.checked) {
      this.endAtTarget.classList.remove('hidden')
    } else {
      this.endAtTarget.classList.add('hidden')
    }
  }
}
