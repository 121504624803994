import { Controller } from 'stimulus'

export default class extends Controller {

  static targets = ['form', 'hasStartsAt', 'startsAt', 'hasEndsAt', 'endsAt']

  initialize() {
    this.updateInputs()
  }

  updateInputs() {
    if (this.hasStartsAtTarget.checked) {
      this.startsAtTarget.classList.remove('hidden')
    } else {
      this.startsAtTarget.classList.add('hidden')
    }

    if (this.hasEndsAtTarget.checked) {
      this.endsAtTarget.classList.remove('hidden')
    } else {
      this.endsAtTarget.classList.add('hidden')
    }
  }
}
