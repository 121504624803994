import { Controller } from 'stimulus'

export default class extends Controller {

  static targets = ['form',
    'hasStartsAt', 'startsAt', 'hasEndsAt', 'endsAt',
    'hasDisplayStartsAt', 'displayStartsAt', 'hasDisplayEndsAt', 'displayEndsAt',
    'hasAutoParticipateNewbie', 'autoParticipateNewbieStartsAt', 'autoParticipateNewbieCountries'
  ];

  initialize() {
    this.updateInputs()
  }

  updateInputs() {
    if (this.hasStartsAtTarget.checked) {
      this.startsAtTarget.classList.remove('hidden')
    } else {
      this.startsAtTarget.classList.add('hidden')
    }

    if (this.hasEndsAtTarget.checked) {
      this.endsAtTarget.classList.remove('hidden')
    } else {
      this.endsAtTarget.classList.add('hidden')
    }

    if (this.hasDisplayStartsAtTarget.checked) {
      this.displayStartsAtTarget.classList.remove('hidden')
    } else {
      this.displayStartsAtTarget.classList.add('hidden')
    }

    if (this.hasDisplayEndsAtTarget.checked) {
      this.displayEndsAtTarget.classList.remove('hidden')
    } else {
      this.displayEndsAtTarget.classList.add('hidden')
    }

    if (this.hasAutoParticipateNewbieTarget.checked) {
      this.autoParticipateNewbieStartsAtTarget.classList.remove('hidden')
      this.autoParticipateNewbieCountriesTarget.classList.remove('hidden')
    } else {
      this.autoParticipateNewbieStartsAtTarget.classList.add('hidden')
      this.autoParticipateNewbieCountriesTarget.classList.add('hidden')
    }
  }
}
